import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/query',
    name: 'query',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/Query.vue')
  },
  {
    path: '/learn/index',
    name: 'learnIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/learn/Learn.vue')
  },
  {
    path: '/learn/questions',
    name: 'learnQuestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/learn/Questions.vue')
  },
  {
    path: '/exam/index',
    name: 'examIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/exam/Exam.vue')
  },
  {
    path: '/exam/questions',
    name: 'examQuestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/exam/Questions.vue')
  },
  {
    path: '/exam/end',
    name: 'examEnd',
    component: () => import(/* webpackChunkName: "about" */ '../views/exam/End.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/Index.vue')
  },
  {
    path: '/user/password',
    name: 'userPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/Password.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
